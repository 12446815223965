import React, { useEffect, useRef, useState } from 'react';
import { ModuleBase, Media, Stack, Text, HeadingTag, ResponsiveImage, FullBleedModal, Box } from '@/components';
import PlayIcon from '@/assets/play.svg';
import Pause from '@/assets/pause.svg';
import DownArrow from '@/assets/downArrow.svg';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import Mute from '@/assets/mute3.svg';
import Sound from '@/assets/sound3.svg';

const HeroFullBleedLeftModule = ({ data, isAnchorBar }) => {
  const [playing, setPlaying] = useState(false);
  const [pauseByUser, setPauseByUser] = useState(false);
  const [hideCoverImage, setCoverImage] = useState(false);
  const video = useRef();
  const videoModal = useRef();
  const { t } = useTranslation('common');
  const [isMuted, setIsMuted] = useState(true);

  const scrollToComponent = () => {
    const blocks = document.getElementsByClassName('module');
    if (blocks && blocks[1]) {
      blocks[1].scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (data.backgroundMedia.autoPlay) {
      setCoverImage(true);
    } else {
      setCoverImage(false);
    }
  }, [data.backgroundMedia.autoPlay]);

  const handleUnmuteClick = () => {
    if (isMuted) {
      video?.current?.unMute();
      setIsMuted(false);
    } else {
      video?.current?.mute();
      setIsMuted(true);
    }
  };

  const handleCloseModal = () => {
    videoModal.current.close();
    video.current.play();
    setPlaying(true);
  };

  const handlePauseClick = () => {
    if (video.current) {
      video.current.pause();
      setPlaying(false);
      setPauseByUser(true);
    }
  };

  const handlePlayVideo = () => {
    video.current.play();
    setCoverImage(true);
    setPauseByUser(false);
  };

  const handleOpenModal = () => {
    video.current.pause();
    videoModal.current.open();
  };

  console.log(hideCoverImage, 'hideCoverImage');

  const preHeading = data.preHeading.split(/\/(.*)/s);

  return (
    <ModuleBase data={data} className="relative h-svh overflow-hidden">
      {data.backgroundMedia && (
        <div className="absolute left-0 right-0 top-0 h-full w-full">
          <Media
            ref={video}
            media={data.backgroundMedia}
            widths={{ xs: 425, sm: 768, md: 1024, lg: 1280, xl: 1440, xxl: 2560 }}
            heights={{ xs: 750, sm: null, md: null, lg: null, xl: null, xxl: null }}
            dataNotLazy
            cover
            background={data.backgroundMedia.autoPlay}
            controls={false}
            muted
            className="overlay after:z-20"
            coverImageProps={{ className: classNames('block absolute inset-0 z-10', hideCoverImage && 'hidden') }}
            preload
            onAutoPlayStarted={
              !pauseByUser
                ? () => {
                    setPlaying(true);
                  }
                : null
            }
            onPlay={() => {
              setPlaying(true);
            }}
          />
        </div>
      )}

      <Stack
        className="container relative z-20 flex h-full flex-col items-start px-6 lg:justify-end lg:px-10"
        role="main"
      >
        <div
          className={classNames(
            'flex flex-col gap-m pl-0 pt-[160px] sm:w-[495px] sm:pt-[184px] lg:w-[780px] lg:flex-row lg:items-end lg:gap-8 lg:pt-0 xl:ml-[116px]',
            isAnchorBar ? 'lg:pb-[184px]' : 'lg:pb-[144px]',
          )}
        >
          {data.logo && (
            <div className="h-fit w-16 lg:w-20">
              <ResponsiveImage animate image={data.logo} widths={{ xs: 80, md: 80, lg: 80 }} heights={{ xs: null }} />
            </div>
          )}
          <div className="title-wrapper">
            {data.preHeading && (
              <Text className="mb-s font-gotham text-small-paragraph font-n-light uppercase not-italic leading-4 text-white/90">
                {preHeading[0]}
                {preHeading[1] && <span className="font-s-light">/ {preHeading[1]}</span>}
              </Text>
            )}
            {data.headingTitle && (
              <HeadingTag
                motion={{
                  variants: {
                    inactive: {
                      y: 50,
                      opacity: 0,
                    },
                    active: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        type: 'spring',
                        damping: 25,
                        delay: 0.3,
                      },
                    },
                  },
                }}
                data={data.headingTitle}
                className="font-larken text-mobile-large font-normal not-italic leading-mobile-large text-white sm:text-desktop-large sm:leading-desktop-large"
              />
            )}
          </div>
        </div>

        <div
          className="absolute bottom-0 z-[1] hidden h-[78px] w-[116px] cursor-pointer items-center justify-center bg-bright-blue lg:flex xl:ml-[120px]"
          onClick={scrollToComponent}
        >
          <DownArrow className="fill-off-white" />
        </div>

        {(data.backgroundMedia?.vimeoId || data.backgroundMedia.mobileVimeoId) && (
          <div
            className={classNames(
              'absolute right-6 z-20 flex gap-3 sm:right-10',
              isAnchorBar ? 'bottom-10 sm:bottom-[90px] lg:bottom-[120px]' : 'bottom-10 sm:bottom-12 lg:bottom-[60px]',
            )}
          >
            <Box className="flex gap-2.5">
              {((!data.backgroundMedia.autoPlay && !data.backgroundMedia.allowFullScreen && !playing) ||
                (data.backgroundMedia.autoPlay && !data.backgroundMedia.allowFullScreen && !playing) ||
                (data.backgroundMedia.autoPlay && data.backgroundMedia.allowFullScreen && playing) ||
                (data.backgroundMedia.autoPlay && data.backgroundMedia.allowFullScreen && !playing) ||
                (!data.backgroundMedia.autoPlay && data.backgroundMedia.allowFullScreen && !playing) ||
                (!data.backgroundMedia.autoPlay && data.backgroundMedia.allowFullScreen && playing)) && (
                <button
                  onClick={() =>
                    (data.backgroundMedia.allowFullScreen && !data.backgroundMedia.autoPlay) ||
                    (data.backgroundMedia.allowFullScreen && data.backgroundMedia.autoPlay && playing)
                      ? handleOpenModal()
                      : handlePlayVideo()
                  }
                  className="flex cursor-pointer items-center gap-2.5 self-end py-1 font-gotham text-xs font-normal uppercase leading-6 text-white [&_.btn]:hover:bg-white/75"
                >
                  <span>
                    {(data.backgroundMedia.allowFullScreen && !data.backgroundMedia.autoPlay) ||
                    (data.backgroundMedia.allowFullScreen && data.backgroundMedia.autoPlay && playing)
                      ? t('general.$watchVideo')
                      : t('general.$playVideo')}
                  </span>
                  <PlayIcon className="btn icon bg-white/25" role="presentation" />
                </button>
              )}

              {playing && (
                <button
                  onClick={handlePauseClick}
                  className={`flex cursor-pointer items-center gap-2.5 self-end py-1 font-gotham text-xs font-normal uppercase leading-6 text-white [&_.btn]:hover:bg-white/75 ${data.backgroundMedia.allowFullScreen && !data.backgroundMedia.autoPlay && playing ? 'hidden' : 'flex'}`}
                >
                  <Pause role="presentation" className="btn icon bg-white/25" />
                </button>
              )}
            </Box>

            <Box
              className={`${data.backgroundMedia.allowFullScreen && !data.backgroundMedia.autoPlay ? 'hidden' : 'flex'}`}
            >
              <div className="flex cursor-pointer items-center gap-2.5 self-end py-1 font-gotham text-xs font-normal uppercase leading-6 text-white [&_.btn]:hover:bg-white/75">
                <button
                  onClick={handleUnmuteClick}
                  className="btn icon bg-white/25"
                  aria-label={t('general.$muteUnmute')}
                >
                  {isMuted ? (
                    <Mute role="presentation" className="h-4 w-4 [&_path]:fill-white" />
                  ) : (
                    <Sound role="presentation" className="h-4 w-4 [&_path]:fill-white" />
                  )}
                </button>
              </div>
            </Box>
          </div>
        )}
      </Stack>
      <div className="absolute bottom-10 z-0 w-full border border-dashed border-white/25 lg:bottom-[46px]" />
      <FullBleedModal ref={videoModal} onClose={handleCloseModal}>
        <div className="size-full p-6">
          <Media
            media={{ ...data.backgroundMedia, autoPlay: true, coverImage: null }}
            className="flex items-center"
            controls
            background={false}
          />
        </div>
      </FullBleedModal>
    </ModuleBase>
  );
};

export default HeroFullBleedLeftModule;
