import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import NavColumn from './NavColumn';

const Nav = ({ open, onClose, nav, setBackgroundImage, parentBackgroundImage, title, isLodgeNav }) => {
  const [activeMenu, setActiveMenu] = useState({ l0: {} });

  useEffect(() => {
    if (!open) {
      setActiveMenu({ l0: {} });
    }
  }, [open]);

  return (
    <div
      className={classNames(
        'dark:bg-gray-800 pointer-events-none fixed left-0 w-full overflow-y-auto overflow-x-hidden transition-all duration-700 ease-out',
        isLodgeNav
          ? 'top-34 h-[calc(100dvh-70px)] lg:h-[calc(100vh-128px)]'
          : 'top-[72px] h-[calc(100dvh-72px)] lg:top-20 lg:h-[calc(100vh-80px)]',
        open ? 'opacity-100' : 'translate-x-full opacity-0 lg:-translate-x-full',
      )}
    >
      {nav?.flyout && (
        <NavColumn
          title={title}
          navItems={nav.flyout.navItems}
          cta={nav.flyout.navCta}
          activeMenu={activeMenu}
          level={0}
          setActiveMenu={(sub) => setActiveMenu({ l0: sub })}
          close={onClose}
          setBackgroundImage={setBackgroundImage}
          parentBackgroundImage={parentBackgroundImage}
        />
      )}
    </div>
  );
};

export default Nav;
