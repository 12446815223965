import React, { useRef, useState } from 'react';
import { ModuleBase, Stack, Box, ResponsiveImage, MediaLightboxCarousel } from '@/components';
import { listingGridItem } from '@/animations';
import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import { AnimatePresence } from 'framer-motion';
import { AutoPlay } from '@egjs/flicking-plugins';
import ExpandIcon from '@/assets/expand.svg';

const carouselMotion = {
  initial: 'inactive',
  whileInView: 'active',
  viewport: { once: true, amount: 0.5 },
  exit: 'inactive',
  variants: {
    inactive: {
      opacity: 0,
    },
    active: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.2,
      },
    },
  },
};

const TickerModule = ({ data }) => {
  const { images } = data || {};
  const [activeImage, setActiveImage] = useState(null);

  const plugins = [
    new AutoPlay({ animationDuration: 3500, delayAfterHover: 500, duration: 0, direction: 'NEXT', stopOnHover: true }),
  ];

  const carousel = useRef();

  const carouselImages = [];
  for (let i = 0; i < 5; i++) {
    carouselImages.push(
      ...images.map((image, index) => ({
        image,
        index,
      })),
    );
  }

  const onHoldEnd = () => {
    carousel.current.activePlugins[0]._onMouseEnter();
    setTimeout(() => {
      carousel.current.activePlugins[0]._onMouseLeave();
    }, 1000);
  };

  return (
    <ModuleBase data={data} className="mx-auto w-full overflow-hidden">
      <MediaLightboxCarousel
        open={activeImage !== null}
        activeImage={activeImage}
        onClose={() => setActiveImage(null)}
        slides={images}
        preloadSlides={images}
      />
      <Stack motion={carouselMotion} onMouseEnter={() => carousel.current?.stopAnimation()}>
        <AnimatePresence>
          <Flicking
            ref={carousel}
            plugins={plugins}
            align="prev"
            circular
            preventDefaultOnDrag
            resizeOnContentsReady={false}
            easing={(x) => x}
            moveType="freeScroll"
            interruptable
            onHoldEnd={onHoldEnd}
          >
            {carouselImages.map((image, index) => (
              <Box
                key={index}
                motion={listingGridItem}
                className="group relative mx-1 h-[216px] sm:h-[332px] lg:h-[432px]"
              >
                <ResponsiveImage
                  heights={{ xs: 432, sm: 332, lg: 432 }}
                  image={image.image}
                  imgProps={{ className: '!w-auto' }}
                />
                <div className="absolute right-6 top-4 transition-opacity duration-200 group-hover:opacity-100 lg:opacity-0">
                  <button
                    className="btn secondary bg-white/10 backdrop-blur-[6px]"
                    aria-label="Expand Image"
                    onClick={() => setActiveImage(image.index)}
                  >
                    <ExpandIcon />
                  </button>
                </div>
              </Box>
            ))}
          </Flicking>
        </AnimatePresence>
      </Stack>
    </ModuleBase>
  );
};
export default TickerModule;
