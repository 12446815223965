import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Dropdown from './Dropdown';
import Datepicker from './Datepicker';
import NumberInput from './NumberInput';
import RadioGroupInput from './RadioGroupInput';
import MultiCheckbox from './MultiCheckbox';
import FormTitleAndDescription from './FormTitleAndDescription';
import { Box, DataConsent } from '..';

const FormControl = ({
  id,
  type,
  label,
  name,
  error,
  options,
  required,
  settings,
  motion,
  animate,
  className,
  formDefinitionName,
  ...props
}) => {
  let realType = null;
  if (['text', 'Short answer'].includes(type) && settings?.fieldType === 'number') {
    realType = 'number';
  } else if (['text', 'Short answer'].includes(type) && settings?.fieldType === 'date') {
    realType = 'date';
  } else if (['text', 'Short answer'].includes(type)) {
    realType = 'text';
  } else if (['checkbox'].includes(type)) {
    realType = 'checkbox';
  } else if (['select', 'Dropdown'].includes(type)) {
    realType = 'dropdown';
  } else if (['textarea', 'Long answer'].includes(type)) {
    realType = 'textarea';
  } else if (['datepicker', 'Date'].includes(type)) {
    realType = 'datepicker';
  } else if (['number'].includes(type)) {
    realType = 'number';
  } else if (['Hidden'].includes(type)) {
    realType = 'hidden';
  } else if (['radio', 'Single choice'].includes(type)) {
    realType = 'radio';
  } else if (['multicheckbox', 'Multiple choice'].includes(type)) {
    realType = 'multicheckbox';
  } else if (['Title and description'].includes(type)) {
    realType = 'titleAndDescription';
  } else if (['reCAPTCHA v3 with score'].includes(type)) {
    realType = 'recaptcha';
  } else if (['Data Consent'].includes(type)) {
    realType = 'dataConsent';
  } else if (['Recaptcha2'].includes(type)) {
    realType = 'Recaptha2';
  }

  let input = null;
  if (['text', 'checkbox'].includes(realType)) {
    input = (
      <input
        id={id}
        type={realType}
        className="input"
        name={name}
        aria-label={props.placeholder}
        aria-required={required}
        {...props}
      />
    );
  } else if (realType === 'dropdown') {
    input = (
      <Dropdown
        id={name}
        className="input dropdown"
        name={name}
        options={options}
        displayEmpty={!required}
        required={required}
        {...props}
        placeholder={props.placeholder}
      />
    );
  } else if (realType === 'textarea') {
    input = (
      <textarea id={name} type={realType} className="input" name={name} rows={settings?.numberOfRows || 3} {...props} />
    );
  } else if (realType === 'datepicker') {
    input = <Datepicker id={name} className="input" name={name} {...props} />;
  } else if (realType === 'number') {
    input = (
      <NumberInput
        id={name}
        className="input"
        type={realType}
        name={name}
        defaultValue={settings?.defaultValue !== '' ? settings?.defaultValue : 0}
        {...props}
      />
    );
  } else if (realType === 'date') {
    input = <Datepicker id={name} className="input" name={name} minDate={new Date()} {...props} />;
  } else if (realType === 'hidden') {
    input = <input id={name} type={realType} className="input" name={name} {...props} />;
  } else if (realType === 'radio') {
    input = <RadioGroupInput options={options} required={required} settings={settings} name={name} {...props} />;
  } else if (realType === 'multicheckbox') {
    input = <MultiCheckbox options={options} required={required} settings={settings} name={name} {...props} />;
  } else if (realType === 'titleAndDescription') {
    input = <FormTitleAndDescription settings={settings} name={name} {...props} />;
  } else if (realType === 'recaptcha') {
    input = <div name={name} className="g-recaptcha" settings={settings} {...props} data-size="invisible"></div>;
  } else if (realType === 'dataConsent') {
    input = <DataConsent name={name} formDefinitionName={formDefinitionName} {...props} />;
  } else if (realType === 'Recaptha2') {
    input = <div></div>;
  }

  const labelElement =
    label &&
    realType !== 'hidden' &&
    (realType === 'checkbox' ? (
      <label htmlFor={id} className="label">
        {label}
      </label>
    ) : realType === 'textarea' ? (
      <label htmlFor={name} className="label">
        {label}
      </label>
    ) : (
      <div htmlFor={name} className="label">
        {label}
      </div>
    ));

  let inputElement;

  if (realType) {
    if (realType === 'checkbox') {
      inputElement = (
        <div className="checkbox">
          {input} {labelElement}
        </div>
      );
    } else if (realType === 'dataConsent') {
      inputElement = input;
    } else if (realType === 'Recaptha2') {
      inputElement = input;
    } else {
      inputElement = (
        <>
          {labelElement} {input}
        </>
      );
    }
  }
  return (
    <Box
      motion={motion}
      animate={animate}
      className={classnames('input-group', name && `field-${name}`, className, { error })}
    >
      {inputElement || (
        <div>
          Input type <b>{type}</b> not implemented
        </div>
      )}
      {error && <div className="error-message">{error}</div>}
    </Box>
  );
};

FormControl.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'checkbox',
    'textarea',
    'select',
    'datepicker',
    'number',
    'radio',
    'multicheckbox',
    'Short answer',
    'Data Consent',
    'Single choice',
    'Long answer',
    'Date',
    'Dropdown',
    'Hidden',
  ]).isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.any,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
  settings: PropTypes.object,
  motion: PropTypes.object,
  animate: PropTypes.bool,
  onChange: PropTypes.func,
};

export default FormControl;
