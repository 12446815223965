export default function imageLoader({ src, width, quality = 75 }) {
  const url = new URL(src, process.env.IMAGE_PROCESSOR_URL);
  const searchParams = new URLSearchParams(url.search);

  const focalPoint = searchParams.get('rxy');
  const imageHeight = searchParams.get('height');

  searchParams.set('width', width.toString());

  const validQuality = !isNaN(quality) && quality > 0 ? quality : 75;
  searchParams.set('quality', validQuality.toString());

  if (focalPoint) {
    searchParams.set('rxy', focalPoint);
  }

  if (imageHeight) {
    searchParams.set('height', imageHeight);
  }

  const isWebpConvertible =
    !src.endsWith('.svg') && !src.endsWith('.gif') && !src.endsWith('.webp') && !src.endsWith('.avif');

  if (isWebpConvertible) {
    searchParams.set('format', 'webp');
  }

  return `${process.env.IMAGE_PROCESSOR_URL}${url.pathname}?${searchParams.toString()}`;
}
