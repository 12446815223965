import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import Flicking from '@egjs/react-flicking';
import tailwindConfig from '../tailwind.config.js';
import '@egjs/react-flicking/dist/flicking.css';
import { Stack, Text, HeadingTag, Box, ResponsiveImage, ModuleBase, CarouselPagination } from '@/components';
import { AnimatePresence } from 'framer-motion';
import { isColorDark, fixColor } from '@/utils';
import { animControllerInView } from '@/animations';

const ItineraryCarouselModule = ({ data }) => {
  const [panelsPerView, setPanelsPerView] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [panelsSize, setPanelsSize] = useState(0);
  const isLight = !isColorDark(fixColor(data.backgroundColour));

  const carousel = useRef();
  const willChange = (e) => {
    setCurrentIndex(e.index);
  };

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth < parseInt(tailwindConfig.theme.screens.sm, 10)) {
        setPanelsPerView(1.03);
      } else {
        setPanelsPerView(2);
      }
      calcPanelsSize();
    };

    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  const calcPanelsSize = async () => {
    setTimeout(() => {
      setPanelsSize(carousel.current.camera?.visiblePanels[0]?.size);
    }, 500);
  };

  return (
    <ModuleBase data={data}>
      <div className="container">
        <Stack className="mb-14 sm:mb-10 lg:mb-16" role="contentinfo" aria-label="contentinfo">
          {data.preHeading && (
            <Text
              className={classNames(
                'mb-3.5 text-center font-gotham text-sm font-n-light leading-6',
                isLight ? 'text-black/75' : 'text-white/75',
              )}
            >
              {data.preHeading}
            </Text>
          )}

          {data.headingTitle && data.headingTitle.heading && (
            <HeadingTag
              animate
              data={data.headingTitle}
              className="color-from-bg text-center font-larken text-desktop-small font-light not-italic leading-mobile-medium sm:text-desktop-medium sm:leading-desktop-medium"
            />
          )}
        </Stack>
      </div>

      <div className="mx-auto w-full max-w-[1920px] overflow-hidden">
        <div className="container flex flex-col gap-m sm:flex-row sm:gap-[48px] lg:gap-[148px]">
          {(data.secondaryPreHeading || data.subHeading || data.description) && (
            <div className="flex flex-col sm:mt-m sm:min-w-[301px] lg:min-w-[316px]">
              {data.secondaryPreHeading && (
                <Text
                  className={classNames(
                    'mb-3 text-small-paragraph font-n-light not-italic leading-desktop-small',
                    isLight ? 'text-black/75' : 'text-white/75',
                  )}
                >
                  {data.secondaryPreHeading}
                </Text>
              )}

              {data.subHeading && (
                <Text
                  className={classNames(
                    'mb-s font-larken text-xl font-normal not-italic leading-desktop-small sm:text-desktop-small sm:leading-desktop-small',
                    isLight ? 'text-black' : 'text-white',
                  )}
                >
                  {data.subHeading}
                </Text>
              )}

              {data.description && (
                <Text
                  dangerouslySetInnerHTML={{ __html: data.description }}
                  className={classNames(
                    'w-full text-small-paragraph font-s-light not-italic leading-desktop-small',
                    isLight ? 'text-black/75' : 'text-white/75',
                  )}
                />
              )}
            </div>
          )}

          <div className="carousel w-full">
            <Stack motion={animControllerInView({ amount: 0.3 })} className="sm:overflow-hidden">
              <Box className="mb-xs text-small-paragraph font-n-light not-italic leading-desktop-small">
                <span className={classNames(isLight ? 'text-black' : 'text-white')}>
                  {(currentIndex + 1 + '').padStart(2, '0')}
                </span>{' '}
                <span className={classNames(isLight ? 'text-black/25' : 'text-white/25')}>/</span>{' '}
                <span className={classNames(isLight ? 'text-black/25' : 'text-white/25')}>
                  {(data.itineraries?.length + '').padStart(2, '0')}
                </span>
              </Box>

              <AnimatePresence>
                <Flicking
                  ref={carousel}
                  panelsPerView={panelsPerView}
                  align="prev"
                  preventDefaultOnDrag
                  className="!overflow-visible"
                  resizeOnContentsReady
                  onWillChange={willChange}
                  onReady={calcPanelsSize}
                  disableOnInit={data.itineraries.length === 1}
                >
                  {data.itineraries?.map((itinerary, index) => (
                    <Box key={index} className="mx-1.5 lg:mx-2">
                      <div
                        className={classNames(
                          'overlay relative aspect-[345/194] cursor-grab transition-opacity duration-500 after:opacity-0 after:transition-all after:duration-500 lg:aspect-[550/309]',
                          index !== currentIndex ? 'opacity-50 after:opacity-100' : '',
                        )}
                      >
                        <ResponsiveImage
                          image={itinerary}
                          widths={{ xs: 381, sm: 338, md: 466, lg: 464, xl: 752 }}
                          heights={{ xs: 214, sm: 190, md: 262, lg: 261, xl: 422 }}
                        />
                      </div>
                      <div
                        className={classNames(
                          'h-fit w-full transition-opacity duration-500 lg:hidden',
                          index !== currentIndex ? 'opacity-0' : '',
                        )}
                      >
                        <p
                          className={classNames(
                            'mt-m line-clamp-1 text-paragraph font-n-light not-italic leading-desktop-small tracking-paragraph sm:mt-3',
                            isLight ? 'text-black' : 'text-white',
                          )}
                        >
                          {itinerary.imageAlt}
                        </p>

                        <div
                          className={classNames(
                            'text-small-paragraph font-s-light not-italic leading-desktop-small',
                            isLight ? 'text-black/75' : 'text-white/75',
                          )}
                          dangerouslySetInnerHTML={{ __html: itinerary.caption }}
                        />
                      </div>
                    </Box>
                  ))}
                  {data.itineraries.length === 1 && <div />}
                </Flicking>
              </AnimatePresence>
            </Stack>
            <Stack
              className="relative mt-m flex flex-col justify-between sm:mt-10 sm:flex-row lg:mt-4"
              style={{ width: panelsSize + 'px' }}
            >
              <Box className="relative hidden h-[70px] w-full lg:block">
                {data.itineraries.map((itinerary, index) => (
                  <div
                    key={index}
                    className={classNames(
                      'absolute size-full transition-opacity duration-500',
                      currentIndex === index ? 'opacity-100' : 'opacity-0',
                    )}
                  >
                    <div className="h-fit w-full">
                      <p
                        className={classNames(
                          'line-clamp-1 text-paragraph font-n-light not-italic leading-desktop-small tracking-paragraph',
                          isLight ? 'text-black' : 'text-white',
                        )}
                      >
                        {itinerary.imageAlt}
                      </p>

                      <div
                        className={classNames(
                          'text-small-paragraph font-s-light not-italic leading-desktop-small',
                          isLight ? 'text-black/75' : 'text-white/75',
                        )}
                        dangerouslySetInnerHTML={{ __html: itinerary.caption }}
                      />
                    </div>
                  </div>
                ))}
              </Box>
              <Box className="flex justify-center gap-s" aria-orientation="horizontal">
                <div className="w-[148px] lg:w-auto">
                  {data.itineraries.length > 1 && (
                    <CarouselPagination
                      carousel={carousel}
                      dark={isLight}
                      className="h-12 sm:h-auto [&_.btn]:hidden [&_.btn]:lg:flex [&_.progress-container]:lg:hidden"
                    />
                  )}
                </div>
              </Box>
            </Stack>
          </div>
        </div>
      </div>
    </ModuleBase>
  );
};

export default ItineraryCarouselModule;
