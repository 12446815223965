import { useRef, useState, useEffect } from 'react';
import {
  CarouselPagination,
  HeadingTag,
  MediaLightboxCarousel,
  ModuleBase,
  ResponsiveImage,
  Stack,
  Text,
} from '@/components';
import '@egjs/flicking-plugins/dist/pagination.css';
import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import MediaMasonryCard from './MediaMasonryCard';
import { AnimatePresence } from 'framer-motion';
import { isColorDark, fixColor } from '@/utils';
import classNames from 'classnames';
import tailwindConfig from '@/tailwind.config.js';

const carouselMotion = {
  initial: 'inactive',
  whileInView: 'active',
  viewport: { once: true, amount: 0.2 },
  exit: 'inactive',
  variants: {
    inactive: {
      opacity: 0,
    },
    active: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.2,
      },
    },
  },
};

const MediaMasonryGridModule = ({ data }) => {
  const { headingTitle, preHeading, description, mediaSection } = data || {};
  const carousel = useRef();
  const [activeImage, setActiveImage] = useState(null);
  const [panelsPerView, setPanelsPerView] = useState(1.02);

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth < parseInt(tailwindConfig.theme.screens.sm, 10)) {
        setPanelsPerView(1.02);
      } else {
        setPanelsPerView(1.3);
      }
    };

    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  const bgDark = isColorDark(fixColor(data?.backgroundColour));

  return (
    <>
      <MediaLightboxCarousel
        open={activeImage !== null}
        activeImage={activeImage}
        onClose={() => setActiveImage(null)}
        slides={mediaSection[0].images}
        preloadSlides={mediaSection[0].images}
      />

      <ModuleBase data={data} className="relative overflow-hidden">
        <Stack className="container mx-auto mb-6 flex flex-col md:max-w-[494px] lg:mb-10 lg:max-w-[664px]">
          <div className="mb-6 lg:mb-10">
            {preHeading && (
              <Text
                className={classNames(
                  'mb-3 text-center font-gotham text-sm font-n-light leading-6 lg:mb-3.5',
                  bgDark ? 'text-white/75' : 'text-black/75',
                )}
              >
                {preHeading}
              </Text>
            )}
            {headingTitle && (
              <HeadingTag
                animate
                data={headingTitle}
                className="color-from-bg mx-auto w-full max-w-[316px] text-center font-larken text-2xl font-light leading-7 md:text-desktop-medium md:leading-desktop-medium"
              />
            )}
          </div>
          {description && (
            <Text
              className={classNames(
                'text-center font-gotham text-sm font-s-light leading-6 text-black/75 lg:text-base lg:tracking-paragraph',
                bgDark ? 'text-white/75' : 'text-black/75',
              )}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </Stack>
        {mediaSection &&
          mediaSection.map((mediaRow, index) => (
            <div key={index} className="container">
              <Stack motion={carouselMotion} className="hidden auto-rows-auto grid-cols-12 gap-6 xl:grid">
                <AnimatePresence>
                  {mediaRow.images[0] && (
                    <MediaMasonryCard
                      key={0}
                      image={mediaRow.images[0]}
                      widths={{ xl: 822 }}
                      heights={{ xl: 588 }}
                      className="col-span-5 aspect-[548/392]"
                      onClick={() => setActiveImage(0)}
                    />
                  )}
                  {mediaRow.images[1] && (
                    <MediaMasonryCard
                      key={1}
                      image={mediaRow.images[1]}
                      widths={{ xl: 822 }}
                      heights={{ xl: 588 }}
                      className="col-span-5 row-start-2 aspect-[548/392]"
                      onClick={() => setActiveImage(1)}
                    />
                  )}
                  {mediaRow.images[2] && (
                    <MediaMasonryCard
                      key={2}
                      image={mediaRow.images[2]}
                      widths={{ xl: 1182 }}
                      heights={{ xl: 1212 }}
                      className="col-span-7 row-span-2 aspect-[788/808]"
                      onClick={() => setActiveImage(2)}
                    />
                  )}
                  {mediaRow.images[3] && (
                    <MediaMasonryCard
                      key={3}
                      image={mediaRow.images[3]}
                      widths={{ xl: 1170 }}
                      heights={{ xl: 588 }}
                      className="col-span-7 aspect-[780/392]"
                      onClick={() => setActiveImage(3)}
                    />
                  )}
                  {mediaRow.images[4] && (
                    <MediaMasonryCard
                      key={4}
                      image={mediaRow.images[4]}
                      widths={{ xl: 834 }}
                      heights={{ xl: 588 }}
                      className="col-span-5 aspect-[556/392]"
                      onClick={() => setActiveImage(4)}
                    />
                  )}
                </AnimatePresence>
              </Stack>
              <div className="relative w-full md:m-auto xl:hidden">
                <Flicking
                  ref={carousel}
                  align="prev"
                  bound
                  className="!overflow-visible pb-6"
                  preventDefaultOnDrag
                  horizontal
                  resizeOnContentsReady
                  panelsPerView={panelsPerView}
                >
                  {mediaRow.images.map((image, index) => (
                    <div
                      key={index}
                      className="relative mr-3 flex aspect-[345/247] w-full cursor-grab flex-col sm:aspect-[548/392] md:mr-4"
                      onClick={() => setActiveImage(index)}
                    >
                      <ResponsiveImage
                        image={image}
                        widths={{ xs: 345, sm: 345, md: 722, lg: 947 }}
                        heights={{ xs: 247, sm: 247, md: 517, lg: 677 }}
                      />
                      <div
                        className="absolute left-0 top-0 size-full"
                        style={{
                          background:
                            'linear-gradient(179deg, rgba(0, 0, 0, 0.40)-2.24%, rgba(0, 0, 0, 0.21)48.55%, rgba(0, 0, 0, 0.00)99.35%)',
                        }}
                      />
                      <p className="absolute left-6 top-6 w-full max-w-[255px] font-larken text-xl font-normal leading-6 text-off-white md:text-2xl md:leading-6">
                        {image.imageAlt}
                      </p>
                    </div>
                  ))}
                </Flicking>
                <Stack className="flex justify-center" role="slider" aria-orientation="horizontal">
                  <CarouselPagination
                    dark={!bgDark}
                    carousel={carousel}
                    className="[&_.progress-container]:w-[148px]"
                  />
                </Stack>
              </div>
            </div>
          ))}
      </ModuleBase>
    </>
  );
};
export default MediaMasonryGridModule;
