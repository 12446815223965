import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { ModuleBase, SearchFilters, EntityCard, Box, Stack, HeadingTag, Button } from '@/components';
import { getSearchFilters } from '@/utils';
import { selectPageId } from '@/store/index';
import { listingGrid, listingGridItem } from '@/animations';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { isColorDark, fixColor } from '@/utils';
import classNames from 'classnames';

const CardListingGridModule = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState();
  const [featuredCard, setFeaturedCard] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const router = useRouter();
  const fetchController = useRef(null);
  const queryData = useRef({ filters: [] });
  const pageId = useSelector(selectPageId);
  const page = useRef(1);
  const cardsContainer = useRef();
  const [cardsContainerHeight, setCardsContainerHeight] = useState(0);
  const { t } = useTranslation('common');

  useEffect(() => {
    let newFilters = { filters: data?.filtersAndCards?.displayFilters ? getSearchFilters(router) : [] };
    if (data?.filtersAndCards?.cards && JSON.stringify(newFilters) === JSON.stringify(queryData.current)) {
      setCards(data.filtersAndCards.cards);
      setFeaturedCard(data.filtersAndCards.featuredCard);
      setTotalPages(data.filtersAndCards.totalPages);
    } else if (JSON.stringify(newFilters) !== JSON.stringify(queryData.current) && pageId) {
      queryData.current = newFilters;
      getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, data]);

  useEffect(() => {
    let newFilters = { filters: data?.filtersAndCards?.displayFilters ? getSearchFilters(router) : [] };
    if (JSON.stringify(newFilters) !== JSON.stringify(queryData.current) && pageId) {
      queryData.current = newFilters;
      setCards([]);
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, router.query]);

  const loadMore = () => {
    page.current = page.current + 1;
    getData(page.current);
  };

  const getData = async (pageNumber) => {
    setLoading(true);
    setCardsContainerHeight(cardsContainer.current.offsetHeight);
    const startTime = Date.now();
    if (fetchController.current) {
      fetchController.current.abort('Aborting request due to new request');
    }
    const controller = new AbortController();
    fetchController.current = controller;

    const prefilters = [];
    data?.filtersAndCards?.filter?.forEach((filter) => {
      const prefilterItems = [];
      filter.filters?.forEach((filterItem) => {
        if (filterItem.isSelected) {
          prefilterItems.push(filterItem.filterGuid);
        }
      });
      if (prefilterItems.length > 0) {
        prefilters.push({ FieldName: filter.filterFieldName, FieldGuids: prefilterItems });
      }
    });

    const reqData = {
      PageId: pageId,
      Filters: [...queryData.current.filters, ...prefilters],
      PageSize: data.filtersAndCards.pageSize,
      PageNumber: pageNumber || 1,
      CardsType: data.filtersAndCards.cardType,
      Featured: 0,
      OrderBy: data.filtersAndCards.orderBy,
    };

    const isPreview = !!router.query.isPreview;

    const dataRes = await fetch(`${!isPreview ? '/api' : ''}/umbraco/api/ProductCard/GetCards`, {
      method: 'POST',
      signal: fetchController.current?.signal,
      body: JSON.stringify(reqData),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    }).catch(console.error);

    if (dataRes && dataRes.ok) {
      const resData = await dataRes.json();

      const setData = () => {
        if (!pageNumber) {
          setCards();
        }
        setTimeout(() => {
          if (pageNumber) {
            setCards([...(cards || []), ...(resData.cards || [])]);
          } else {
            setCards(resData.cards || []);
            if (queryData.current.filters.length === 0) {
              setFeaturedCard(data.filtersAndCards.featuredCard);
            } else {
              setFeaturedCard(resData.featuredCard);
            }
            setTotalPages(resData.totalPages);
            page.current = 1;
          }
          setLoading(false);
          setCardsContainerHeight(0);
        }, 0);
      };
      const requestTime = Date.now() - startTime;
      if (requestTime >= 1300) {
        setData();
      } else {
        setTimeout(setData, 1300 - requestTime);
      }
    } else {
      setLoading(false);
      setCardsContainerHeight(0);
    }
  };

  const isDark = isColorDark(fixColor(data?.backgroundColour));

  const nrOfCards = ['Article', 'Person', 'Experience'].includes(data?.filtersAndCards?.cardType) ? 3 : 2;

  const filters = data?.filtersAndCards?.filter?.filter(
    (filter) => !filter.filters.find((filterItem) => filterItem.isSelected),
  );

  return (
    <ModuleBase data={data} className="py-20">
      <div className="container">
        <Stack>
          {data.headingTitle?.heading && (
            <HeadingTag
              animate
              data={data.headingTitle}
              className="color-from-bg pb-xs pt-xxs text-center font-larken text-desktop-small font-light not-italic leading-mobile-medium sm:pb-xl sm:pt-s sm:text-desktop-medium sm:leading-desktop-medium"
            />
          )}
          {data?.filtersAndCards?.displayFilters && (
            <SearchFilters filters={filters} queryMode className="relative z-20 mb-s" dark={isDark} />
          )}
        </Stack>

        <div ref={cardsContainer} style={{ minHeight: cardsContainerHeight }}>
          {(cards || featuredCard) && (
            <Stack
              motion={listingGrid}
              className={classNames(
                'grid grid-cols-1 gap-xs sm:grid-cols-2',
                nrOfCards === 3 ? 'lg:grid-cols-3' : 'lg:grid-cols-2',
              )}
            >
              <AnimatePresence>
                {featuredCard && (
                  <Box
                    motion={listingGridItem}
                    key={featuredCard.moduleId}
                    className={classNames('flex lg:col-span-2', nrOfCards === 3 ? 'lg:col-span-3' : 'lg:col-span-2')}
                  >
                    <EntityCard data={featuredCard} large backgroundColour={data.backgroundColour} />
                  </Box>
                )}
                {cards?.map((card) => (
                  <Box
                    motion={listingGridItem}
                    key={card.moduleId}
                    className={classNames(cards.length === 1 && !featuredCard && 'sm:col-span-3')}
                  >
                    <EntityCard
                      data={card}
                      key={card.moduleId}
                      backgroundColour={data.backgroundColour}
                      large={cards.length === 1 && !featuredCard}
                    />
                  </Box>
                ))}
              </AnimatePresence>
            </Stack>
          )}
        </div>

        <Stack className="mt-10 flex h-12 w-full justify-center">
          <Box>
            {loading ? (
              // eslint-disable-next-line jsx-a11y/alt-text, @next/next/no-img-element
              <img
                aria-label="loading spinner"
                className={classNames('size-6 animate-spin', isDark && 'invert')}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJUSURBVHgBtZZLaxNRGIa/zHRCkqop2BqhQatopeBCEQRxIaK4VXTlxp2iCxfiyj/g39CNiCLuFARBBEHEuhFEBDfiFAXvWmKbzGR8XuYMpqWdps30hYdzmZnvPfczJcvXEGyDMdgIdQgggd/wET7An+UClJapL8Nu2OxMyr7vl+M4VvCAfEBe7wx5nhd0u9135F840xUNRmE8CIJKp9PR86/uw1lo9TRA5lOwF3yI4Tm8zDPQUDQI7hP8O/lPEFm+NsFh2AcePIOn2UO/58W6a31Clz+Tiq6trHl4D22Ga0+pVNqRJInqwl4D3wWXNCQ/bPWaIXAbgynS7ZRfw1xmUK9Wq0EURbNrDJ4pJPgwPdlFOk55WmMmtEo6pL9scD0GxZqEqnqgFeG32+05S8dzUEW0foRe7CSN/GazWUbWarX+WrqBilDAXBzEwPPCMEwqlYqWYj8rpl/NsBJ9ejGq8Y8bjYYMimq99M3SE2CrNlq22Yo0kG4qpucCFx28ZunemvdsfTThDEIZTDDj2r2XrTgddQavVBgBbXGdPXUbXFvgPtyDMfXgJzxhzepCuWaD6yyNVVydqF+ySh1OOv10VFy0tesUPIDblvZkgS7hrDtA83HBVq/T8Agewsmssvc+mHbpIYyOkOo0fGNLXIOLtME16Jwr34K72cOlrszzcBWGLb0G9bIm7K39v9w1XwdgP405wfzVXENuWDrBlmcgNeEKH5/RiUi+Sz52eZnGKqueOqVajtctvQWtH4Neo+NwDCYJWiOgDkX9rmhRaFjvWM5vyz+xy72ACMlncgAAAABJRU5ErkJggg=="
              />
            ) : (
              page.current < totalPages && (
                <Button className={classNames('btn secondary', !isDark && 'dark')} onClick={loadMore}>
                  {t('general.$loadMore')}
                </Button>
              )
            )}
          </Box>
        </Stack>
        {loading}
      </div>
    </ModuleBase>
  );
};
export default CardListingGridModule;
