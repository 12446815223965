import Image from 'next/image';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import tailwindConfig from '../../tailwind.config.js';
import { Box } from '../index.js';

function screenArray() {
  const screens = [];
  const twScreens = tailwindConfig.theme.screens;
  for (const [key, value] of Object.entries(twScreens)) {
    if (typeof value === 'string') {
      screens.push({ key, value });
    }
  }
  return screens.sort((a, b) => parseInt(a.value) - parseInt(b.value));
}

function generateSizesFromWidths(widths) {
  if (!widths) return '100vw';
  const screens = screenArray();

  const sizes = screens
    .filter((screen) => widths[screen.key])
    .map((screen) => {
      return `(min-width: ${screen.value}) ${widths[screen.key]}px`;
    });

  sizes.reverse();
  sizes.push('100vw');

  return sizes.join(', ');
}

const ResponsiveImage = ({
  animate,
  motion,
  image,
  widths,
  preload,
  dataNotLazy,
  cdnProps,
  imgProps,
  className,
  ...props
}) => {
  if (!image?.imageUrl) return null;

  const isSvg = image.imageUrl.endsWith('.svg');

  const altText = image.imageAlt
    ? image.imageAlt.substring(0, 100)
    : image.caption
      ? image.caption.substring(0, 100)
      : `image-${image.mediaId}`;

  const sizes = generateSizesFromWidths(widths);
  const { className: imageClassName, ..._imgProps } = imgProps || {};

  const imageElement = isSvg ? (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      src={image.imageUrl}
      alt={altText}
      className={classNames('block h-full w-full object-cover', imageClassName)}
      data-not-lazy={dataNotLazy}
      cdn-props={cdnProps}
      {..._imgProps}
    />
  ) : (
    <Image
      src={image.imageUrl}
      alt={altText}
      fill
      priority={preload}
      sizes={sizes}
      className={classNames('object-cover', imageClassName)}
      {..._imgProps}
    />
  );

  return (
    <>
      {/* {(preload || isRobot) && ( */}
      {/*   <Head> */}
      {/*     <link rel="preload" as="image" href={image.imageUrl} imageSrcSet={image.imageUrl} fetchpriority="high" /> */}
      {/*   </Head> */}
      {/* )} */}
      {!animate && !motion ? (
        <div className={classNames('relative h-full w-full', className)} {...props}>
          {imageElement}
        </div>
      ) : (
        <Box motion={motion} className={classNames('relative h-full w-full', className)} {...props}>
          {imageElement}
        </Box>
      )}
    </>
  );
};

ResponsiveImage.propTypes = {
  image: PropTypes.object,
  widths: PropTypes.object,
  preload: PropTypes.bool,
  dataNotLazy: PropTypes.bool,
  cdnProps: PropTypes.object,
  imgProps: PropTypes.object,
  className: PropTypes.string,
  animate: PropTypes.bool,
  motion: PropTypes.object,
};

export default ResponsiveImage;
