import Image from 'next/image';
import BgGraphic from '@/assets/quoteBgGraphic.png';
import BgGraphicDark from '@/assets/quoteBgGraphicDark.png';
import { CarouselPagination, ModuleBase, Stack, Text, Box } from '@/components';
import { stripHtml } from '@/utils';
import Flicking, { FlickingError } from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import classNames from 'classnames';
import { useRef } from 'react';
import { useTranslation } from 'next-i18next';
import CarouselArrow from '@/assets/carouselArrow.svg';
import { animControllerInView, fadeIn } from '@/animations';
import { isColorDark, fixColor } from '@/utils';

const QuoteCarouselModule = ({ data }) => {
  const carousel = useRef();
  const { t } = useTranslation('common');

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  const isDark = isColorDark(fixColor(data.backgroundColour));

  return (
    <ModuleBase data={data} className="relative overflow-hidden">
      <Image
        className={classNames(
          'pointer-events-none absolute right-0 w-[603px] scale-125 select-none md:w-full lg:w-[1004px]',
          data.paddingTop.toLowerCase() === 'large' ? '-mt-16' : '',
        )}
        role="presentation"
        src={isDark ? BgGraphic : BgGraphicDark}
        alt="presentation"
      />

      <div className="container px-6 pb-4 pt-2 md:px-10 md:pb-2 md:pt-4 lg:px-[156px] lg:pb-[72px] lg:pt-[74px]">
        <Flicking ref={carousel} align="center" useResizeObserver circular>
          {data.quotes.map((slide, index) => (
            <Stack
              motion={animControllerInView({ once: false, amount: 0.3, staggerChildren: 0.05 })}
              key={index}
              className="w-full cursor-grab"
            >
              <div className="author-source-wrapper mb-6 lg:mb-11">
                {slide.source && (
                  <Text
                    motion={fadeIn}
                    dangerouslySetInnerHTML={{ __html: slide.source }}
                    className={classNames(
                      'mb-1 w-fit font-gotham text-sm font-s-light leading-6 md:mb-0',
                      isDark ? 'text-white/75' : 'text-black/75',
                    )}
                  />
                )}
                {slide.author && (
                  <Text
                    motion={fadeIn}
                    dangerouslySetInnerHTML={{ __html: slide.author }}
                    className={classNames(
                      'w-fit font-gotham text-base font-n-light tracking-paragraph',
                      isDark ? 'text-white/75' : 'text-black/75',
                    )}
                  />
                )}
              </div>

              <div className="quote-wrapper relative z-[10]">
                {slide.quote && (
                  <Text
                    motion={fadeIn}
                    dangerouslySetInnerHTML={{ __html: '"' + stripHtml(slide.quote) + '"' }}
                    className={classNames(
                      'indent-6 font-gotham text-base font-s-light sm:text-2xl md:indent-20',
                      isDark ? 'text-white/60' : 'text-black/60',
                    )}
                  />
                )}
              </div>
            </Stack>
          ))}
        </Flicking>
        <Stack className="mt-10 justify-between md:flex lg:mt-[91px]">
          <CarouselPagination
            carousel={carousel}
            className="mx-auto w-[316px] md:mx-0 md:w-[148px] md:[&_button]:!hidden"
            dark={!isDark}
          />
          <Box className="hidden md:flex">
            <button
              className={classNames('btn icon', !isDark && 'dark')}
              onClick={move}
              aria-label={t('general.previous')}
            >
              <CarouselArrow className="rotate-0" />
            </button>
            <button
              className={classNames('btn icon', !isDark && 'dark')}
              onClick={() => move(1)}
              aria-label={t('general.next')}
            >
              <CarouselArrow className="rotate-180" />
            </button>
          </Box>
        </Stack>
      </div>
    </ModuleBase>
  );
};
export default QuoteCarouselModule;
