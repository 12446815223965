import { ModuleBase, Text, HeadingTag, Box, Stack } from '@/components';
import TopGraphicBlue from '@/assets/richTextTopGraphicBlue.svg';
import BottomGraphicBlue from '@/assets/richTextBottomGraphicBlue.svg';
import TopGraphicWhite from '@/assets/richTextTopGraphicWhite.svg';
import BottomGraphicWhite from '@/assets/richTextBottomGraphicWhite.svg';
import { isColorDark, fixColor } from '@/utils';
import classNames from 'classnames';

const StatsAndTextModule = ({ data }) => {
  const { headingTitle, stats } = data || {};

  const backgroundColour = data?.backgroundColour || '#16242E';
  const darkBg = isColorDark(fixColor(backgroundColour));

  return (
    <ModuleBase data={{ ...data, backgroundColour: backgroundColour }} className="relative overflow-hidden">
      {darkBg ? (
        <TopGraphicBlue className="absolute top-0 h-[180px] w-[289px] select-none lg:h-[192px] lg:w-[316px]" />
      ) : (
        <TopGraphicWhite className="absolute top-0 h-[180px] w-[289px] select-none lg:h-[192px] lg:w-[316px]" />
      )}
      <div
        className="absolute left-0 top-0 h-[272px] w-full select-none"
        style={{ background: `linear-gradient(to top, rgba(0, 0, 0, 0) 6.46%, ${backgroundColour} 94.7%)` }}
      />
      {darkBg ? (
        <BottomGraphicBlue className="absolute -bottom-[42px] -right-[60px] h-[342px] w-[327px] select-none md:-bottom-[160px] md:-right-[50px] md:h-[559px] md:w-[535px] lg:-bottom-[85px] lg:right-[100px]" />
      ) : (
        <BottomGraphicWhite className="absolute -bottom-[42px] -right-[60px] h-[342px] w-[327px] select-none md:-bottom-[160px] md:-right-[50px] md:h-[559px] md:w-[535px] lg:-bottom-[85px] lg:right-[100px]" />
      )}

      <div
        className="absolute bottom-0 left-0 h-[135px] w-full select-none bg-gradient-to-t from-deep-blue from-[6.46%] to-transparent to-[94.7%]"
        style={{ background: `linear-gradient(to top, ${backgroundColour}  6.46%, rgba(0, 0, 0, 0) 94.7%)` }}
      />
      <div className="container relative flex flex-col items-center px-6 py-6 md:px-10 md:py-14 lg:px-[156px] lg:pb-[70px] lg:pt-[74px]">
        <Stack>
          {headingTitle && (
            <HeadingTag
              animate
              data={headingTitle}
              className={classNames(
                'mb-6 max-w-[364px] text-center font-larken text-2xl font-light leading-7 md:mb-[55px] md:max-w-[494px] md:text-desktop-medium md:leading-desktop-medium lg:mb-[59px] lg:max-w-[416px]',
                darkBg ? 'text-white' : 'text-black',
              )}
            />
          )}
        </Stack>
        {stats.length > 0 && (
          <Stack
            className={classNames(
              'flex w-full flex-col gap-2 px-6 py-8 md:max-w-[494px] lg:max-w-[1128px] lg:gap-6 lg:p-10',
              darkBg ? 'bg-white/10' : 'bg-black/10',
            )}
          >
            <div className="flex w-full flex-col gap-m lg:flex-row lg:gap-xs">
              {stats.map((element, index) => (
                <Box key={index} className="flex w-full flex-col gap-xxs lg:flex-row lg:gap-4">
                  <Text
                    className={classNames(
                      'font-gotham text-base font-normal tracking-paragraph',
                      darkBg ? 'text-off-white' : 'text-deep-blue',
                    )}
                  >
                    {element.statisticsLabel}
                  </Text>
                  <Text
                    className={classNames(
                      'richtext-content text-sm font-s-light leading-6 lg:hidden [&_p]:m-0',
                      darkBg ? 'text-white/75' : 'text-black/75',
                    )}
                    dangerouslySetInnerHTML={{ __html: element.statistics }}
                  />
                </Box>
              ))}
            </div>
            <div className="hidden w-full gap-xs lg:flex">
              {stats.map((element, index) => (
                <Box key={index} className="w-full">
                  <Text
                    className={classNames(
                      'richtext-content text-sm font-s-light leading-6 [&_p]:m-0',
                      darkBg ? 'text-white/75' : 'text-black/75',
                    )}
                    dangerouslySetInnerHTML={{ __html: element.statistics }}
                  />
                </Box>
              ))}
            </div>
          </Stack>
        )}
      </div>
    </ModuleBase>
  );
};

export default StatsAndTextModule;
