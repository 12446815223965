/** @type {import('tailwindcss').Config} */

module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './modules/**/*.{js,ts,jsx,tsx}',
    './stories/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    container: {
      center: true,
      screens: {
        xxl: '1600px',
      },
    },
    screens: {
      xs: '480px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      xxl: '1536px',
    },
    colors: {
      black: '#000000',
      white: '#ffffff',
      transparent: 'transparent',
      current: 'currentColor',
      'off-white': '#F7F2E8',
      tan: '#DED9D3',
      'deep-green': '#0E1B16',
      'deep-blue': '#16242E',
      grey: ' #555',
      'bright-orange': '#EB4B24',
      'bright-green': '#465C2C',
      'bright-blue': '#1A71A4',
      'dark-green': '#15251F',
      'dark-blue': '#264355',
      error: '#B41717',
      'error-dark': '#ff4444',

      grey1: '#323232',
      grey2: '#505050',
      grey3: '#828282',
      grey4: '#BEBEBE',
      grey5: '#DCDCDC',
      grey6: '#F0F0F0',
    },
    extend: {
      animation: {
        grow: 'grow 0.7s forwards',
        shrink: 'shrink 0.5s forwards',
      },
      backgroundImage: {
        overlay: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0.2018382352941176) 0%)',
        overlayLeftToRight: 'linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.5074404761904762) 100%)',
      },

      keyframes: {
        grow: {
          '0%': { transform: 'scale(0)' },
          '50%': { transform: 'scale(1.2)' },
          '100%': { transform: 'scale(1)' },
        },
        shrink: {
          '0%': { transform: 'scale(1)' },
          '100%': { transform: 'scale(0)' },
        },
      },
      content: {
        externalLink: 'url("../assets/externalLink.svg")',
        phone: 'url("../assets/phone.svg")',
        mail: 'url("../assets/mail.svg")',
        ellipse: 'url("../assets/ellipse.svg")',
        mapPlus: 'url("../assets/mapPlus.svg")',
        mapMinus: 'url("../assets/mapMinus.svg")',
        facility: 'url("../assets/facility.svg")',
      },
      spacing: {
        22: '5.5rem',
        26: '6.5rem',
        xxxs: '0.25rem',
        xxs: '0.5rem',
        xs: '1rem',
        sm: '1.25rem',
        s: '1.5rem',
        m: '2rem',
        lg: '2rem',
        xl: '3rem',
        xxl: '4rem',
        xxxl: '6rem',
      },
      letterSpacing: {
        paragraph: '0.16px',

        heading1: '0.72px',
        heading1m: '0.48px',
        heading5: '0.24px',
        heading6: '0.22px',
      },
      transitionProperty: {
        width: 'width',
        top: 'top',
      },
      margin: {
        25: '6.25rem',
      },
      padding: {
        39: '10.75rem',
      },
      aspectRatio: {
        '4/3': '4 / 3',
        '3/4': '3 / 4',
        '8/5': '8 / 5',
        '7/6': '7 / 6',
        '6/7': '6 / 7',
      },
      fontFamily: {
        larken: ['Larken', 'ui-sans-serif', 'system-ui', '-apple-system'],
        gotham: ['Gotham', 'ui-sans-serif', 'system-ui', '-apple-system'],
      },
      fontWeight: {
        'n-light': 350,
        's-light': 325,
      },
      fontSize: {
        'desktop-extra-large': '98px',
        'desktop-large': '56px',
        'desktop-medium': '32px',
        'desktop-small': '24px',
        'mobile-large': '48px',
        paragraph: '16px',
        'small-paragraph': '14px',
        'tiny-paragraph': '12px',

        btn: ['12px', '20px'],
        heading4: '28px',
        heading5: '26px',
        heading6: '22px',
        'xl-paragraph': '18px',
        'small-caption': '10px',
      },
      lineHeight: {
        'desktop-extra-large': '90px',
        'desktop-large': '56px',
        'desktop-medium': '34px',
        'desktop-small': '24px',
        'mobile-large': '40px',
        'mobile-medium': '28px',
        'large-paragraph': '32px',
        'tiny-paragraph': '14px',

        heading1: '96px',
        heading1m: '64px',
        heading2: '52px',
        heading6: '30px',
        paragraph: '20px',
      },
    },
  },
  plugins: [require('tailwind-scrollbar-hide'), require('tailwind-scrollbar')],
};
