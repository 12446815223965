import { ModuleBase, HeadingTag, Stack, Text } from '@/components';
import TopGraphicBlue from '@/assets/richTextTopGraphicBlue.svg';
import BottomGraphicBlue from '@/assets/richTextBottomGraphicBlue.svg';
import TopGraphicWhite from '@/assets/richTextTopGraphicWhite.svg';
import BottomGraphicWhite from '@/assets/richTextBottomGraphicWhite.svg';
import { isColorDark, fixColor } from '@/utils';
import { motion } from 'framer-motion';
import classNames from 'classnames';

const OffsetRichTextModule = ({ data }) => {
  const { headingTitle, preHeading } = data;

  const backgroundColour = data?.backgroundColour || '#ffffff';
  const darkBg = isColorDark(fixColor(backgroundColour));
  return (
    <ModuleBase data={{ ...data, backgroundColour }} className="relative overflow-hidden">
      {darkBg ? (
        <TopGraphicBlue className="absolute top-0 h-[180px] w-[289px] select-none lg:h-[192px] lg:w-[316px]" />
      ) : (
        <TopGraphicWhite className="absolute top-0 h-[180px] w-[289px] select-none lg:h-[192px] lg:w-[316px]" />
      )}
      <div
        className="absolute left-0 top-0 h-[272px] w-full select-none"
        style={{ background: `linear-gradient(to top, rgba(0, 0, 0, 0) 6.46%, ${backgroundColour} 94.7%)` }}
      />
      {darkBg ? (
        <BottomGraphicBlue className="absolute -bottom-[130px] right-4 h-[483px] w-[462px] select-none lg:bottom-[-162px] lg:h-[559px] lg:w-[535px]" />
      ) : (
        <BottomGraphicWhite className="absolute -bottom-[130px] right-4 h-[483px] w-[462px] select-none lg:bottom-[-162px] lg:h-[559px] lg:w-[535px]" />
      )}
      <div
        className="absolute bottom-0 left-0 h-[135px] w-full select-none bg-gradient-to-t from-deep-blue from-[6.46%] to-transparent to-[94.7%]"
        style={{ background: `linear-gradient(to top, ${backgroundColour}  6.46%, rgba(0, 0, 0, 0) 94.7%)` }}
      />
      <div className="container relative flex flex-col px-6 sm:my-10 sm:px-10 lg:mb-[43px] lg:mt-[123px] lg:gap-0 lg:px-39">
        <Stack className="mb-m flex flex-col sm:mb-10 sm:w-[234px] lg:mb-3 lg:w-[410px]">
          {preHeading && (
            <Text
              tag="p"
              className={classNames(
                'mb-3 font-gotham text-sm font-n-light leading-6',
                darkBg ? 'text-white/75' : 'text-black/75',
              )}
            >
              {preHeading}
            </Text>
          )}
          {headingTitle && (
            <HeadingTag
              animate
              data={headingTitle}
              className="color-from-bg font-larken text-2xl font-light leading-7 md:text-desktop-medium md:leading-desktop-medium lg:w-[316px]"
            />
          )}
        </Stack>
        <div className="flex justify-end">
          <div className="flex w-full flex-col sm:w-[500px] xl:w-[664px] xxl:w-[900px]">
            {data.texts.map((text, index) => {
              return (
                <div key={index} className="mb-10 flex flex-col last:mb-0">
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.9, ease: 'easeOut' }}
                    viewport={{ once: true, amount: 0.05 }}
                  >
                    <HeadingTag
                      data={text.headingTitle}
                      className={classNames(
                        'mb-xs text-paragraph font-n-light not-italic leading-desktop-small tracking-paragraph',
                        darkBg ? 'text-off-white' : 'text-deep-blue',
                      )}
                    />
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.9, ease: 'easeOut' }}
                    viewport={{ once: true, amount: 0.05 }}
                    className={classNames(
                      'richtext-content font-gotham text-small-paragraph font-s-light not-italic leading-desktop-small [&_p]:my-1 [&_ul]:my-0',
                      darkBg ? 'text-white/50' : 'text-black/80',
                    )}
                    dangerouslySetInnerHTML={{ __html: text.content }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ModuleBase>
  );
};
export default OffsetRichTextModule;
