import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Link, PopoverMenu, ModalPortal } from '@/components';
import { useTranslation } from 'next-i18next';
import { isColorDark, fixColor } from '@/utils';

const AnchorBarModule = ({ data }) => {
  const [hideFixed, setHideFixed] = useState(false);
  const [activeModule, setActiveModule] = useState();
  const anchorContainer = useRef();

  const backgroundColor = fixColor(data?.backgroundColour);
  const isDark = isColorDark(data.backgroundColour);

  useEffect(() => {
    const ids = Array.from(document.querySelectorAll('.page-container > .module'))
      .map((el) => el.id)
      .filter((id) => id);
    const firstId = ids[0];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const fromTop = entry.intersectionRect?.top;
          const fromBottom = entry.rootBounds?.height - entry.intersectionRect?.bottom;

          if (fromTop > fromBottom) {
            if (entry.target.id === firstId) {
              setActiveModule(null);
            }
          }

          if (fromBottom > fromTop) {
            const activeElements = ['CardListingGridModule', 'ListColumnsModule'];
            if (!activeElements.some((element) => entry.target.classList.contains(element))) {
              setActiveModule(null);
            }
          }
          if (entry.isIntersecting && entry.target.id) {
            setActiveModule(entry.target.id ? '#' + entry.target.id : null);
          }
        });
      },
      {
        rootMargin: '0px 0px -30% 0px',
        threshold: 0.4,
      },
    );
    document.querySelectorAll('.page-container > .module').forEach((el) => observer.observe(el));

    const onScroll = () => {
      if (
        anchorContainer.current.getBoundingClientRect().top -
          window.innerHeight +
          anchorContainer.current.getBoundingClientRect().height <=
        0
      ) {
        setHideFixed(true);
      } else {
        setHideFixed(false);
      }
    };

    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <>
      {!hideFixed && (
        <ModalPortal>
          <div
            className="fixed bottom-0 z-40 w-full"
            style={{
              backgroundColor,
            }}
          >
            <AnchorBarContent
              activeModule={activeModule}
              data={data}
              isDark={isDark}
              backgroundColor={backgroundColor}
            />
          </div>
        </ModalPortal>
      )}
      <div
        ref={anchorContainer}
        className="w-full"
        style={{
          backgroundColor,
        }}
      >
        <AnchorBarContent activeModule={activeModule} data={data} isDark={isDark} backgroundColor={backgroundColor} />
      </div>
    </>
  );
};

const AnchorBarContent = ({ activeModule, data, isDark, backgroundColor }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('common');

  const scrollTo = (e, element) => {
    e.preventDefault();
    e.stopPropagation();
    const elementByHash = document.querySelector(element.href, { lerp: 0.5 });

    if (elementByHash) {
      if (window.lenis) {
        window.lenis.scrollTo(elementByHash);
      } else {
        const currentScroll = window.scrollY;
        const offset = currentScroll > elementByHash.offsetTop ? 110 : 0;
        window.scrollTo({
          top: elementByHash.offsetTop - offset,
          behavior: 'smooth',
        });
      }
      window.history.replaceState(null, null, element.href);
    }

    setOpen(false);
  };

  const links =
    data.anchorLinkItems &&
    data.anchorLinkItems.map(
      (element, index) =>
        element.text && (
          <div key={index} className="flex items-center">
            <Link
              className={classnames(
                "before:size-2 before:rounded-full before:border before:content-[''] sm:before:hidden",
                'flex items-center gap-3 whitespace-nowrap text-paragraph not-italic leading-desktop-small tracking-paragraph duration-200 lg:text-paragraph lg:leading-desktop-small lg:tracking-paragraph',
                'font-s-ligh',
                isDark ? 'before:border-white/50 before:bg-white/50' : 'before:border-black/10 before:bg-black/10',
                isDark
                  ? activeModule === element.href
                    ? 'text-white'
                    : 'text-white/50'
                  : activeModule === element.href
                    ? 'text-black'
                    : 'text-black/50',

                {
                  'before:!bg-bright-orange': activeModule === element.href,
                },
              )}
              link={{ href: element.href }}
              onClick={(e) => scrollTo(e, element)}
            >
              {element.text}
            </Link>
          </div>
        ),
    );

  return (
    <div className="container h-[73px] sm:h-20">
      <div className="flex h-full max-w-[100vw] items-center gap-xxs">
        <div className="relative hidden h-full grow overflow-hidden sm:flex [&_.anchor-link.active]:text-black">
          <div
            className={classnames(
              'flex grow gap-10 overflow-x-auto pr-16 scrollbar-thin scrollbar-track-transparent',
              isDark ? 'scrollbar-thumb-white/25' : 'scrollbar-thumb-black/25',
            )}
          >
            {links}
          </div>
          <div
            className="pointer-events-none absolute right-0 top-0 h-full w-[87px]"
            style={{ background: `linear-gradient(270deg, ${backgroundColor} 0%, rgba(38, 67, 85, 0.00) 100%)` }}
          />
        </div>

        <div className="flex grow sm:hidden [&_.open_.popover-title]:font-n-light [&_.open_svg]:rotate-0">
          <PopoverMenu
            open={open}
            offset={16}
            placement="top"
            onOpen={() => setOpen(true)}
            title={t('general.$highlights')}
            className={classnames(
              'w-full [&_.content-wrapper]:min-w-fit',
              '[&_svg]:rotate-180',
              '[&_.content]:flex [&_.content]:flex-col [&_.content]:gap-s [&_.content]:rounded-sm [&_.content]:p-xs',
              '[&_.popover-title]:text-paragraph [&_.popover-title]:font-s-light [&_.popover-title]:leading-desktop-small [&_.popover-title]:tracking-paragraph',
              '[&_.main-button]:px-4 [&_.main-button]:py-3',
              isDark
                ? 'bg-white/5 [&_.popover-title]:text-white [&_svg]:stroke-white'
                : 'bg-black/5 [&_.popover-title]:text-black [&_svg]:stroke-black',
            )}
            contentStyle={{ backgroundColor: backgroundColor, borderColor: backgroundColor }}
          >
            {links}
          </PopoverMenu>
        </div>

        <div className="ml-3 flex overflow-hidden sm:ml-10 sm:overflow-visible">
          <Link
            className={classnames('btn primary w-full max-w-[200px] whitespace-nowrap md:max-w-max', !isDark && 'dark')}
            link={data.cta}
          />
        </div>
      </div>
    </div>
  );
};

export default AnchorBarModule;
