import React, { useEffect, useRef, useState } from 'react';
import { ModuleBase, Media, Stack, Text, HeadingTag, Link, FullBleedModal, Box } from '@/components';
import PlayIcon from '@/assets/play.svg';
import Pause from '@/assets/pause.svg';
import Mute from '@/assets/mute3.svg';
import Sound from '@/assets/sound3.svg';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

const HeroFullBleedModule = ({ data }) => {
  const [playing, setPlaying] = useState(false);
  const [pauseByUser, setPauseByUser] = useState(false);
  const [hideCoverImage, setCoverImage] = useState(false);
  const video = useRef();
  const videoModal = useRef();
  const { t } = useTranslation('common');
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    if (data.backgroundMedia.autoPlay) {
      setCoverImage(true);
    } else {
      setCoverImage(false);
    }
  }, [data.backgroundMedia.autoPlay]);

  const handleUnmuteClick = () => {
    if (isMuted) {
      video?.current?.unMute();
      setIsMuted(false);
    } else {
      video?.current?.mute();
      setIsMuted(true);
    }
  };

  const handleCloseModal = () => {
    videoModal.current.close();
    video.current.play();
    setPlaying(true);
  };

  const handlePauseClick = () => {
    if (video.current) {
      video.current.pause();
      setPlaying(false);
      setPauseByUser(true);
    }
  };

  const handlePlayVideo = () => {
    video.current.play();
    setCoverImage(true);
    setPauseByUser(false);
  };

  const handleOpenModal = () => {
    video.current.pause();
    videoModal.current.open();
  };

  return (
    <ModuleBase data={data} className="relative h-svh overflow-hidden lg:h-screen">
      {data.backgroundMedia && (
        <div className="absolute left-0 right-0 top-0 h-full w-full">
          <Media
            ref={video}
            media={data.backgroundMedia}
            widths={{ xs: 425, sm: 768, md: 1024, lg: 1280, xl: 1440, xxl: 2560 }}
            heights={{ xs: 750, sm: null, md: null, lg: null, xl: null, xxl: null }}
            dataNotLazy
            cover
            background={data.backgroundMedia.autoPlay}
            controls={false}
            muted
            className="overlay after:z-20"
            coverImageProps={{ className: classNames('block absolute inset-0 z-10', hideCoverImage && 'hidden') }}
            preload
            onAutoPlayStarted={
              !pauseByUser
                ? () => {
                    setPlaying(true);
                  }
                : null
            }
            onPlay={() => {
              setPlaying(true);
            }}
          />
        </div>
      )}

      <Stack
        className="container relative z-30 flex h-full flex-col items-center justify-center px-6 text-center lg:px-10"
        role="contentinfo"
      >
        <div className="title-wrapper">
          {data.preHeading && (
            <Text className="text-subheading my-4 font-larken text-base font-semibold uppercase text-white">
              {data.preHeading}
            </Text>
          )}
          {data.headingTitle && (
            <HeadingTag
              motion={{
                variants: {
                  inactive: {
                    y: 50,
                    opacity: 0,
                  },
                  active: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: 'spring',
                      damping: 25,
                      delay: 0.3,
                    },
                  },
                },
              }}
              data={data.headingTitle}
              className="color-from-bg max-w-[922px] font-larken text-desktop-large font-light leading-desktop-large !text-white sm:capitalize"
            />
          )}
          {data.subHeading && (
            <Text className="text-subheading my-4 font-larken text-base font-semibold text-white">
              {data.subHeading}
            </Text>
          )}
        </div>

        {(data.description || data.primaryCTA || data.secondaryCTA) && (
          <div className="color-from-bg mb-4 mt-5 text-heading6 font-light not-italic leading-8 tracking-heading6">
            <Text dangerouslySetInnerHTML={{ __html: data.description }} className="text-white" />

            {(data.primaryCTA || data.secondaryCTA) && (
              <div className="mt-15 flex justify-center gap-4 sm:mt-16 lg:mt-20">
                <Link animate className="btn primary" link={data.primaryCTA} />

                <Link animate className="btn secondary" link={data.secondaryCTA} />
              </div>
            )}
          </div>
        )}

        {(data.backgroundMedia?.vimeoId || data.backgroundMedia.mobileVimeoId) && (
          <div className="absolute bottom-9 right-6 flex gap-3 sm:bottom-12 sm:right-10 md:bottom-12 lg:bottom-[60px]">
            <Box className="flex gap-2.5">
              {((!data.backgroundMedia.autoPlay && !data.backgroundMedia.allowFullScreen && !playing) ||
                (data.backgroundMedia.autoPlay && !data.backgroundMedia.allowFullScreen && !playing) ||
                (data.backgroundMedia.autoPlay && data.backgroundMedia.allowFullScreen && playing) ||
                (data.backgroundMedia.autoPlay && data.backgroundMedia.allowFullScreen && !playing) ||
                (!data.backgroundMedia.autoPlay && data.backgroundMedia.allowFullScreen && !playing) ||
                (!data.backgroundMedia.autoPlay && data.backgroundMedia.allowFullScreen && playing)) && (
                <button
                  onClick={() =>
                    (data.backgroundMedia.allowFullScreen && !data.backgroundMedia.autoPlay) ||
                    (data.backgroundMedia.allowFullScreen && data.backgroundMedia.autoPlay && playing)
                      ? handleOpenModal()
                      : handlePlayVideo()
                  }
                  className="flex cursor-pointer items-center gap-2.5 self-end py-1 font-gotham text-xs font-normal uppercase leading-6 text-white [&_.btn]:hover:bg-white/75"
                >
                  <span>
                    {(data.backgroundMedia.allowFullScreen && !data.backgroundMedia.autoPlay) ||
                    (data.backgroundMedia.allowFullScreen && data.backgroundMedia.autoPlay && playing)
                      ? t('general.$watchVideo')
                      : t('general.$playVideo')}
                  </span>
                  <PlayIcon className="btn icon bg-white/25" role="presentation" />
                </button>
              )}

              {playing && (
                <button
                  onClick={handlePauseClick}
                  className={`flex cursor-pointer items-center gap-2.5 self-end py-1 font-gotham text-xs font-normal uppercase leading-6 text-white [&_.btn]:hover:bg-white/75 ${data.backgroundMedia.allowFullScreen && !data.backgroundMedia.autoPlay && playing ? 'hidden' : 'flex'}`}
                >
                  <Pause role="presentation" className="btn icon bg-white/25" />
                </button>
              )}
            </Box>

            <Box
              className={`${data.backgroundMedia.allowFullScreen && !data.backgroundMedia.autoPlay ? 'hidden' : 'flex'}`}
            >
              <div className="flex cursor-pointer items-center gap-2.5 self-end py-1 font-gotham text-xs font-normal uppercase leading-6 text-white [&_.btn]:hover:bg-white/75">
                <button
                  onClick={handleUnmuteClick}
                  className="btn icon bg-white/25"
                  aria-label={t('general.$muteUnmute')}
                >
                  {isMuted ? (
                    <Mute role="presentation" className="h-4 w-4 [&_path]:fill-white" />
                  ) : (
                    <Sound role="presentation" className="h-4 w-4 [&_path]:fill-white" />
                  )}
                </button>
              </div>
            </Box>
          </div>
        )}
      </Stack>
      <FullBleedModal ref={videoModal} onClose={handleCloseModal}>
        <div className="size-full p-6">
          <Media
            media={{ ...data.backgroundMedia, autoPlay: true, coverImage: null }}
            className="flex items-center"
            controls
            background={false}
          />
        </div>
      </FullBleedModal>
    </ModuleBase>
  );
};

export default HeroFullBleedModule;
