import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { Link, Nav, NavBgImage } from '@/components';
import LodgeNav from '@/components/nav/LodgeNav';
import classNames from 'classnames';
import Menu from '@/assets/menu.svg';
import Close from '@/assets/close.svg';
import DropdownArrow from '@/assets/dropdown_arrow.svg';
import ElevenLogo from '@/assets/elevenLogo.svg';
import tailwindConfig from '@/tailwind.config.js';

const Header = ({ header, transparentHeader }) => {
  const [showHeader, setShowHeader] = useState(false);
  const [solidBG, setSolidBG] = useState(!transparentHeader);
  const [menuActive, setMenuActive] = useState(false);
  const [mainOpen, setMainOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [destinationOpen, setDestinationOpen] = useState(false);
  const [adventureOpen, setAdventureOpen] = useState(false);
  const [currentBgImage, setCurrentBgImage] = useState(header.mainNav.navItemBackgroundImage || null);
  const prevScrollPos = useRef(0);
  const { t } = useTranslation('common');

  useEffect(() => {
    const onScroll = () => {
      const currentScrollPos = window.scrollY;

      if (transparentHeader) {
        setSolidBG(currentScrollPos >= window.innerHeight);
      }

      if (currentScrollPos > Math.max(prevScrollPos.current, 10)) {
        setShowHeader(false);
      } else if (currentScrollPos < prevScrollPos.current || window.scrollY === 0) {
        setShowHeader(true);
      }
      prevScrollPos.current = currentScrollPos;
    };

    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    setShowHeader(true);
    return () => window.removeEventListener('scroll', onScroll);
  }, [transparentHeader]);

  useEffect(() => {
    setSolidBG(!transparentHeader);
  }, [transparentHeader]);

  const onMenuClick = () => {
    if (window.innerWidth < parseInt(tailwindConfig.theme.screens.lg, 10)) {
      _setMobileOpen(!mobileOpen);
    } else {
      _setMainOpen(!mainOpen);
    }
  };

  const _setMainOpen = (mainOpen) => {
    if (mainOpen) {
      setMenuActive(mainOpen);
      setMainOpen(mainOpen);
      setBackgroundImage(header?.mainNav?.navItemBackgroundImage);
    } else {
      setBackgroundImage(null);
      setMenuActive(false);
      setMainOpen(false);
    }
    setDestinationOpen(false);
    setAdventureOpen(false);
    setMobileOpen(false);
  };

  const _setMobileOpen = (mobileOpen) => {
    setMenuActive(mobileOpen);
    setMobileOpen(mobileOpen);
    if (mobileOpen) {
      setBackgroundImage(header?.mainNav?.navItemBackgroundImage);
    } else {
      setBackgroundImage(null);
    }
    setDestinationOpen(false);
    setAdventureOpen(false);
    setMainOpen(false);
  };

  const _setDestinationOpen = (destinationOpen) => {
    setMenuActive(false);
    setMainOpen(false);
    setDestinationOpen(destinationOpen);
    setAdventureOpen(false);
    setMobileOpen(false);
    if (destinationOpen) {
      setBackgroundImage(header?.destinationNav?.navItemBackgroundImage);
    } else {
      setBackgroundImage(null);
    }
  };

  const _setAdventureOpen = (adventureOpen) => {
    setMenuActive(false);
    setMainOpen(false);
    setDestinationOpen(false);
    setMobileOpen(false);
    setAdventureOpen(adventureOpen);
    if (adventureOpen) {
      setBackgroundImage(header?.adventureNav?.navItemBackgroundImage);
    } else {
      setBackgroundImage(null);
    }
  };

  const setBackgroundImage = (image) => {
    if (image) {
      setCurrentBgImage(image);
    }
  };

  const btnClassName = classNames(
    'font-larken text-sm font-medium uppercase not-italic leading-5',
    'cursor-pointer flex-row-reverse items-center gap-2 xl:gap-2.5 py-3 lg:flex-row h-12',
    (mainOpen || mobileOpen || destinationOpen || adventureOpen) && 'text-white',
    solidBG ? 'text-black group-hover:lg:text-white' : 'text-white',
  );

  const extraNavs = [
    {
      title: t('general.$lodgeDestinations'),
      onClick: () => _setDestinationOpen(!destinationOpen),
      active: destinationOpen,
    },

    {
      title: t('general.$guidedAdventures'),
      onClick: () => _setAdventureOpen(!adventureOpen),
      active: adventureOpen,
    },
  ];

  const mobileNavItems = [];

  if (!header.destinationNav.hideOnMobile) {
    mobileNavItems.push({
      navItemText: t('general.$lodgeDestinations'),
      flyout: header.destinationNav.flyout,
    });
  }

  if (!header.adventureNav.hideOnMobile) {
    mobileNavItems.push({
      navItemText: t('general.$guidedAdventures'),
      flyout: header.adventureNav.flyout,
    });
  }

  const mobileNav = {
    ...header.mainNav,
    flyout: {
      ...header.mainNav.flyout,
      navItems: [...mobileNavItems, ...header.mainNav.flyout.navItems],
    },
  };

  return (
    <header
      className={classNames(
        'group fixed z-50 w-full backdrop-blur-[8px] transition-all duration-1000',
        showHeader ? 'top-0' : '-top-[74px] lg:-top-20',
        solidBG && showHeader && 'shadow-lg',
      )}
    >
      <div
        className={classNames(
          'pointer-events-none absolute left-0 top-0 w-screen bg-black/50 transition-all duration-700 ease-out lg:pointer-events-auto lg:duration-500 lg:ease-in-out',
          mainOpen || mobileOpen || destinationOpen || adventureOpen
            ? 'disable-scroll h-dvh opacity-100 lg:h-screen'
            : 'h-0 translate-x-full opacity-0 lg:translate-x-0',
        )}
        onClick={() => _setMainOpen(false)}
      >
        <NavBgImage header={header} currentBgImage={currentBgImage} />
      </div>

      <div
        className={classNames(
          'content relative transition-colors duration-500 lg:duration-1000 group-hover:lg:bg-black',
          mainOpen || mobileOpen || destinationOpen || adventureOpen ? 'bg-black' : solidBG && 'bg-white',
        )}
      >
        <div className="container relative flex flex-row-reverse items-center justify-between px-s py-3 sm:px-xs lg:flex-row lg:p-4">
          <div className="flex gap-6 lg:w-5/12">
            <button
              onClick={onMenuClick}
              className={classNames('flex lg:pr-5', btnClassName)}
              aria-label={t('general.$menu')}
            >
              <div className="relative m-1.5">
                <Menu
                  className={classNames(
                    'duration-1000',
                    (mainOpen || mobileOpen || destinationOpen || adventureOpen) && 'fill-white',
                    solidBG ? 'fill-black group-hover:lg:fill-white' : 'fill-white',
                    menuActive && 'opacity-0',
                  )}
                  role="presentation"
                />
                <Close
                  className={classNames(
                    'absolute -top-px left-0.5 fill-white duration-1000',
                    !menuActive && 'opacity-0',
                  )}
                  role="presentation"
                />
              </div>
              <div className="relative">
                <span
                  className={classNames(
                    'text-center font-gotham text-btn font-normal uppercase not-italic leading-desktop-small duration-1000 sm:block',
                    menuActive && 'opacity-0',
                  )}
                >
                  {t('general.$menu')}
                </span>
                <span
                  className={classNames(
                    'absolute right-0 top-0 text-center font-gotham text-btn font-normal uppercase not-italic leading-desktop-small duration-1000 sm:block lg:left-0 lg:right-auto',
                    !menuActive && 'opacity-0',
                  )}
                >
                  {t('general.$close')}
                </span>
              </div>
            </button>

            {extraNavs.map(({ title, onClick, active }) => (
              <button
                key={title}
                onClick={onClick}
                className={classNames('hidden lg:flex', btnClassName)}
                aria-label={title}
              >
                <span className="hidden text-center font-gotham text-btn font-normal uppercase not-italic leading-desktop-small duration-1000 sm:block">
                  {title}
                </span>
                <div className="relative m-1.5">
                  <div className={classNames('duration-300', active && 'rotate-180')}>
                    <DropdownArrow
                      className={classNames(
                        'duration-1000',
                        (mainOpen || mobileOpen || destinationOpen || adventureOpen) && 'stroke-white',
                        solidBG ? 'stroke-black group-hover:lg:stroke-white' : 'stroke-white',
                      )}
                      role="presentation"
                    />
                  </div>
                </div>
              </button>
            ))}
          </div>
          <div className="flex justify-center lg:w-2/12">
            <Link link={{ href: '/' }} className="lg:p-2.5">
              {/*
              header?.logo && (
                <ResponsiveImage image={header.logo} widths={{ xs: 80 }} heights={{ xs: 24 }} dataNotLazy />
              )*/}
              <ElevenLogo
                className={classNames(
                  'duration-1000',
                  (mainOpen || mobileOpen || destinationOpen || adventureOpen) && 'fill-white',
                  solidBG ? 'fill-black group-hover:lg:fill-white' : 'fill-white',
                )}
                role="presentation"
              />
            </Link>
          </div>
          <div className="hidden w-5/12 justify-end gap-4 lg:flex">
            {header.secondaryCta && (
              <Link
                className={classNames(
                  'btn primary border-off-white/25 !bg-transparent hover:!bg-bright-orange hover:!text-black',
                  (mainOpen || mobileOpen || destinationOpen || adventureOpen) && '!text-off-white',
                  solidBG ? 'group-hover:lg:text-off-white' : '!text-off-white',
                )}
                link={header.secondaryCta}
              />
            )}
            {header.primaryCta && <Link className={classNames('btn primary')} link={header.primaryCta} />}
          </div>
        </div>
      </div>
      <LodgeNav
        className={classNames(
          'content transition-colors duration-500 lg:duration-1000 group-hover:lg:bg-black group-hover:lg:hover:[&_.nav-link]:text-white',
          mainOpen || destinationOpen || adventureOpen
            ? 'bg-black [&_.nav-link]:text-white/50'
            : solidBG
              ? 'bg-tan [&_.nav-link]:text-black/50 group-hover:lg:[&_.nav-link]:text-white/50'
              : 'bg-black/10 [&_.nav-link]:text-white/50',
          !mobileOpen ? 'flex' : 'hidden',
        )}
        navItems={header?.lodgeNavigation?.navItems}
      />

      <Nav
        open={mainOpen}
        onClose={() => _setMainOpen(false)}
        nav={header?.mainNav}
        setBackgroundImage={setBackgroundImage}
        parentBackgroundImage={header?.mainNav?.navItemBackgroundImage}
        extraNavs={extraNavs}
        isLodgeNav={header?.lodgeNavigation}
      />
      <Nav
        open={destinationOpen}
        onClose={() => _setMainOpen(false)}
        nav={header?.destinationNav}
        setBackgroundImage={setBackgroundImage}
        parentBackgroundImage={header?.destinationNav?.navItemBackgroundImage}
        title={t('general.$lodgeDestinations')}
        isLodgeNav={header?.lodgeNavigation}
      />
      <Nav
        open={adventureOpen}
        onClose={() => _setMainOpen(false)}
        nav={header?.adventureNav}
        setBackgroundImage={setBackgroundImage}
        parentBackgroundImage={header?.adventureNav?.navItemBackgroundImage}
        title={t('general.$guidedAdventures')}
        isLodgeNav={header?.lodgeNavigation}
      />

      <Nav
        open={mobileOpen}
        onClose={() => _setMainOpen(false)}
        nav={mobileNav}
        setBackgroundImage={setBackgroundImage}
        parentBackgroundImage={header?.adventureNav?.navItemBackgroundImage}
        isLodgeNav={header?.lodgeNavigation}
      />
    </header>
  );
};

export default Header;
